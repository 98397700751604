<template>
  <div>
    <van-row class="header">
      <van-col span="6">
        <img :src="userInfo.avatar" />
      </van-col>
      <van-col span="10">{{ userInfo.nickname }}</van-col>
      <van-col span="8">
        <van-icon name="arrow" />
      </van-col>
    </van-row>

    <van-cell-group>
      <van-cell icon="orders-o" title="我的订单" is-link @click="toMyActivityOrderList" />
    </van-cell-group>
    <van-cell-group>
      <van-cell icon="gold-coin-o" title="我的分佣" is-link @click="toMyProfitShareList" />
      <van-cell icon="friends-o" title="我的团队" is-link @click="toMyTeamList" />
    </van-cell-group>

    <!-- <van-grid :column-num="3" square>
      <van-grid-item icon="photo-o" text="我的订单" @click="handleMyOrders" />
      <van-grid-item icon="photo-o" text="文字" @click="iconClick" />
      <van-grid-item icon="photo-o" text="文字" @click="iconClick" />
      <van-grid-item icon="photo-o" text="文字" @click="iconClick" />
    </van-grid> -->

    <div v-if="showModal" class="loginFormModal">
      <section @click="closeModal" />
      <van-form @submit="onSubmit">
        <van-field v-model="username" name="用户名" label="用户名" placeholder="用户名" :rules="[{ required: true, message: '请填写用户名' }]" />
        <van-field v-model="password" type="password" name="密码" label="密码" placeholder="密码" :rules="[{ required: true, message: '请填写密码' }]" />
        <div style="margin: 16px;">
          <van-button round block type="info" native-type="submit">提交</van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      avatar: require('@/assets/logo.png'),
      username: '',
      password: '',
      nickname: '立刻登录',
      showModal: false,
      appId: '',
      extParam: ''
    }
  },
  computed: {
    userInfo() {
      if (process.env.NODE_ENV === 'development') {
        return { avatar: this.avatar, nickname: this.nickname }
      } else {
        return this.$store.getters.userInfo
      }
    }
  },
  created() {
    this.appId = this.$route.params.appId
    this.extParam = this.$route.query.extParam
  },
  methods: {
    toMyActivityOrderList() {
      this.$router.push(`/${this.appId}/ucenter/order/list`)
    },
    toMyProfitShareList() {
      this.$router.push(`/${this.appId}/ucenter/profitShare/list`)
    },
    toMyTeamList() {
      this.$router.push(`/${this.appId}/ucenter/groupOn/teamList`)
    },
    handleMyOrders() {
      // this.$router.push('/myOrders')
      this.$toast('该功能尚未开放')
    },
    iconClick() {
      this.$toast('该功能尚未开放')
    },
    onSubmit(values) {
      console.log('submit', values)
      setTimeout(() => {
        this.showModal = false
        // 显示
        this.nickname = 'Akio'
        this.avatar = 'https://thirdwx.qlogo.cn/mmopen/vi_32/bPzDXoibpS5hfaKRia33tvexXmQ81BUnybejT3QMTj1OU03vhRAVNxoPichsvMEZlNggJib35FGticZhiaQar1NYNS3g/132'
      }, 1000)
    },
    closeModal() {
      this.showModal = false
    }
  }
}
</script>

<style lang="less" scoped>
.van-row {
  padding: 10px;
  background-color: #333;
  color: #fff;
  .van-col {
    line-height: 80px;
    font-size: 16px;
    img {
      border-radius: 50%;
      width: 80px;
      display: block;
    }
    &:last-child {
      text-align: right;
    }
  }
}
.van-cell-group {
  margin-bottom: 10px;
  margin-top: 10px;
}
.loginFormModal {
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 99;
  section {
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .van-form {
    background-color: #fff;
    width: 90%;
    position: absolute;
    top: 36vh;
    left: 50%;
    margin-left: -45%;
    padding: 6px;
    box-sizing: border-box;
  }
}
</style>
